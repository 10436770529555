<template>
  <div>
    <a-spin size="large" :spinning="spinning">
      <a-page-header title="返回" @back="goBack" />
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item v-if="!isEdit">
          <span class="info">基本信息</span>
        </a-form-model-item>
        <a-row>
          <a-col :md="10" :sm="24" v-if="!isEdit">
            <a-form-model-item label="账号：" :labelCol="{ span: 4 }" :wrapperCol="{ span: 16, offset: 1 }"
              prop="card_number">
              <a-input v-if="isEdit" v-model="show_staff_phone" placeholder="请输入账号" :disabled="isEdit" />
              <a-input v-else v-model="form.card_number" placeholder="请输入账号" :disabled="isEdit" />
            </a-form-model-item>
          </a-col>
          <a-col :md="10" :sm="24" v-if="!isEdit">
            <a-form-model-item label="真实姓名：" :labelCol="{ span: 4 }" :wrapperCol="{ span: 16, offset: 1 }"
              prop="staff_name">
              <a-input v-model="form.staff_name" placeholder="请输入真实姓名" />
            </a-form-model-item>
          </a-col>
          <a-col :md="10" :sm="24" v-if="!isEdit">
            <a-form-model-item label="手机号：" :labelCol="{ span: 4 }" :wrapperCol="{ span: 16, offset: 1 }"
              prop="staff_phone">
              <a-input v-model="form.staff_phone" placeholder="请输入手机号"
                @input="(e) => (form.staff_phone = validNumKeyBoard(e))" />
            </a-form-model-item>
          </a-col>
          <a-col :md="10" :sm="24" v-if="!isEdit">
            <a-form-model-item label="加入企业：" prop="enterprise_id" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 16, offset: 1 }">
              <!-- :getPopupContainer="(triggerNode) => triggerNode.parentNode" -->
              <a-select show-search allowClear placeholder="请选择" :filter-option="false" :not-found-content="null"
                @select="selectEnterPrise" @search="handleSearch" :value="form.enterprise_id">
                <a-select-option v-for="item in allProject" :key="item.id" :value="item.id">
                  {{ item.enterprise_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="10" :sm="24" v-if="isSelectedEnter || !isEdit">
            <a-form-model-item label="角色权限：" prop="role_id" :labelCol="{ span: 4 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-select v-model="form.role_id" :placeholder="'请选择'" mode="multiple">
                <a-select-option v-for="(item, index) in allRole" :key="index" :value="item.role_id">
                  {{ item.role_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="10" :sm="24" v-if="isSelectedEnter || !isEdit">
            <a-button
              type="primary"
              @click="confirmJoin"
              style="margin-top: 3px"
            >
              确认加入
            </a-button>
          </a-col> -->
          <a-col :span="24" v-if="joinEnterData.length">
            <a-table :rowKey="(record, index) => record.enterprise_id" :columns="columns" :data-source="joinEnterData"
              bordered :pagination="false" style="margin-left: 100px; margin-right: 100px">
              <template slot="role" slot-scope="text, record">
                <editable-cell :text="text" :roleId="record.role_id_list" :allRole="allRole"
                  @change="onCellChange(record.enterprise_id, 'role', $event)" v-if="isEdit" />
                <span v-else>{{ text }}</span>
              </template>
            </a-table>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-button type="primary" :disabled="submitInfoBut" @click="submitInfo" style="margin-right: 10px;" v-if="showBtn"> 提 交 </a-button>
          <a-button class="m-20" type="danger" ghost @click="emptyInfo" v-if="!isEdit"> 清 空 </a-button>
        </a-row>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import * as api from '@/api/enterprise/enterprise.js'
import debounce from 'lodash/debounce'
import  EditableCell  from './EditableCell.vue';

export default {
  name: '',
  components: {
    EditableCell,
  },
  data() {
    return {
      submitInfoBut: false,
      spinning: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      // 在编辑和查看的时候显示员工手机号
      show_staff_phone: '',
      form: {
        // 员工名称
        staff_name: '',
        // 员工手机号
        staff_phone: '',
        // 员工账号
        card_number: '',
        // 记录选中企业id
        enterprise_id: '',
        // 记录角色id
        role_id: [],
      },
      rules: {
        card_number: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        staff_phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { max: 11, message: '请输入11位手机号', trigger: 'change' },
          { min: 11, message: '请输入11位手机号', trigger: 'change' },
          { max: 11, message: '请输入11位手机号', trigger: 'blur' },
          { min: 11, message: '请输入11位手机号', trigger: 'blur' },
        ],
        staff_name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { max: 20, message: '真实姓名最多输入20个字符', trigger: 'change' },
        ],
        enterprise_id: [
          { required: true, message: '请选择加入企业', trigger: 'blur' },
        ],
        role_id: [{ required: true, message: '请分配角色', trigger: 'blur' }],
      },
      columns: [
        {
          key: 'enterpriseName',
          title: '企业名称',
          dataIndex: 'enterprise_name',
          width: '50%',
          align: 'center',
        },
        {
          title: '角色',
          className: 'column-role',
          dataIndex: 'role_name',
          width: '50%',
          align: 'center',
          scopedSlots: { customRender: 'role' },
        },
      ],

      // 所有可加入的企业
      allProject: [],
      // 所有可选角色
      allRole: [],
      // 是否选中企业
      isSelectedEnter: false,
      // 加入企业数据
      joinEnterData: [],

      // 是否编辑
      isEdit: false,
      // 员工id
      staffId: '',
      showBtn: true
    }
  },
  methods: {
    // 选择企业
    selectEnterPrise(id) {
      this.isSelectedEnter = true
      this.form.enterprise_id = id
    },
    // 确定加入
    // confirmJoin: debounce(function() {
    //   if (!this.verifyData()) return
    //   let joinProject = {}
    //   this.allProject.forEach((item) => {
    //     if (item.enterprise_id === this.form.enterprise_id) {
    //       joinProject = { ...item }
    //     }
    //   })
    //   this.allRole.forEach((item) => {
    //     if (item.role_id === this.form.role_id) {
    //       joinProject = { ...joinProject, ...item }
    //     }
    //   })
    //   if (
    //     !this.joinEnterData.some(
    //       (item) => item.enterprise_id === joinProject.enterprise_id
    //     )
    //   ) {
    //     this.joinEnterData.push(joinProject)
    //   } else {
    //     this.$message.error('一个账号只能加入同一公司一次')
    //   }
    // }, 500),
    // 提交
    submitInfo() {
      if (!this.verifyData()) return
      if (this.isEdit) {
        this.editStaff()
      } else {
        this.addStaff()
      }
    },
    // 清空
    emptyInfo() {
      this.form = {
        staff_name: '',
        staff_phone: '',
        card_number: '',
        enterprise_id: '',
        role_id: '',
      }
      this.joinEnterData = []
      this.isSelectedEnter = false
    },

    //在列表编辑角色
    onCellChange(key, dataIndex, value) {
      let str = ''
      this.joinEnterData.forEach((item) => {
        if (item.enterprise_id === key) {
          this.$set(item, 'role_id', value.role_id)
          item.role_id_list = []
          value.forEach((itm) => {
            str += itm.role_name + ' ';
            item.role_id_list.push(itm.role_id)
          })
          this.$set(item, 'role_name', str)
        }
      })
    },

    // 模糊搜索 企业列表 数据
    getComponies: debounce(async function (keyWord) {
      try {
        // 企业搜索优化
        const result = await api.getEnterpriseName({ enterprise_name: keyWord, enter_link_person: '', phone: '' }, 0, 100)
        console.log(result,'============>>>>');
        this.allProject = result.data.data
      } catch (err) {
        console.error(err);
      }
    }, 500),

    handleSearch(value) {
      if (value.trim().length > 0) {
        // 输入时 根据value 远程拉去 数据
        this.getComponies(value.trim())
      }
    },

    // 获取角色列表
    async getRoleList() {
      let tempArr = []
      try {
        const result = await api.getRoleList()
        tempArr=[
          {
            role_id: '02fd3953f4000001',
            role_name:'企业员工',
          },
          {
            role_id: '02fd3953f4000002',
            role_name:'企业管理员',
          },
          {
            role_id: '03738d05ad800000',
            role_name:'人力资源管理员',
          },
        ]
        // result.data.data.forEach((item) => {
        //   tempArr.push({
        //     role_id: item.roleId,
        //     role_name: item.roleName,
        //   })
        // })
        this.allRole = tempArr
      } catch (error) { }
    },
    // 添加员工
    async addStaff() {
      this.submitInfoBut = true
      let staffInfo = { ...this.form }
      const { enterprise_id, role_id } = this.form
      staffInfo.enterprise_roles = [];
      staffInfo.ent_id = enterprise_id
      role_id.forEach((item) => {
        staffInfo.enterprise_roles.push({ enterprise_id, role_id: item })
      })
      // staffInfo.enterprise_roles = [{enterprise_id,role_id}]
      staffInfo.join_source = 2
      // staffInfo.enterprise_roles = this.joinEnterData
      try {
        this.spinning = true
        console.log('staffInfo', staffInfo.ent_id);
        const result = await api.addStaff(staffInfo)
        this.spinning = false
        this.submitInfoBut = false
        if (result.data === '该用户未注册') {
          this.$message.error(result.data)
        } else if (result.data) {
          this.$message.success('添加成功')
          this.goBack()
        } else {
          this.$message.error(result.error)
        }
      } catch (error) {
        console.log(error);
        this.spinning = false
        this.submitInfoBut = false
        this.$message.error(error.data.error)
      }
    },
    // 获取员工详情
    async getStaffDetail() {
      try {
        this.spinning = true
        const { data } = await api.getStaffDetail(this.staffId)

        this.spinning = false
        if (data.code === "200") {
          this.joinEnterData = data.data.enterprise_roles
          this.form = {
            staff_name: data.data.staff_name,
            staff_phone: data.data.staff_phone,
            card_number: data.data.card_number,
            enterprise_id: '',
            role_id:
              this.joinEnterData.length > 0
                ? this.joinEnterData[0].role_id
                : '',
          }
          this.show_staff_phone = data.data.staff_phone
        }
        if (data.code === "400") {
          this.showBtn = false
          this.$message.error(data.msg)
        }
      } catch (error) {
        this.spinning = false
      }
    },
    // 编辑员工
    async editStaff() {
      this.submitInfoBut = true
      let staffInfo = { ...this.form }
      staffInfo.enterprise_roles = this.joinEnterData
      staffInfo.card_number = this.form.card_number ? this.form.card_number : '',
        staffInfo.join_source = 2
      try {
        this.spinning = true
        const result = await api.editStaff(staffInfo, this.staffId)
        this.spinning = false
        this.submitInfoBut = false
        if (result.data) {
          this.$message.success('修改成功')
          this.goBack()
        } else {
          this.$message.error(result.error)
        }
      } catch (error) {
        this.spinning = false
        this.submitInfoBut = false
        this.$message.error(error.data.error)
      }
    },

    // 进行数据校验
    verifyData() {
      let isLegal = false
      if (!this.form.card_number && !this.isEdit) {
        this.$message.info('请输入账号')
      } else if (!this.form.staff_phone) {
        this.$message.info('请输入手机号')
      } else if (!this.form.staff_name) {
        this.$message.info('请输入真实姓名')
      } else if (!this.form.enterprise_id && !this.isEdit) {
        this.$message.info('请加入企业')
      } else if (!this.form.role_id && !this.isEdit) {
        this.$message.info('请分配角色')
      } else {
        isLegal = true
      }
      return isLegal
    },
    // 返回
    goBack() {
      this.$router.go(-1)
      // 针对 path === look 时 返回到 企业列表-人员列表
      // if (this.$route.query.from === "look") {
      //   this.$router.push({ name: "look", query: { id: this.$route.query.enterprise_id } });
      // } else {
      //   this.$router.push({
      //     path: "/enterprise/userList",
      //     params: {
      //       type: "add",
      //     },
      //   });
      // }
    },
  },
  created() {
    this.getRoleList()
    this.staffId = this.$route.query.id
    this.isEdit = !!this.$route.query.isEdit
    if (this.isEdit) {
      this.getStaffDetail()
    }
  },
}
</script>

