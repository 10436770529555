<template>
  <div class="editable-cell">
    <span v-show="!editable">{{ value }}</span>
    <a-icon type="edit" class="editable-cell-icon" @click="edit" v-show="!editable" />
    <div v-show="editable" class="selectClass">
      <a-select ref="mySelect" v-model="selectedRoleId" :placeholder="'请选择'" @change="selectedRole" mode="multiple"
        @blur='blur'>
        <a-select-option v-for="(item, index) in allRole" :key="index" :value="item.role_id">
          {{ item.role_name }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    roleId: Array,
    allRole: Array
  },
  created() {
    this.selectedRoleId = this.roleId ? this.roleId : undefined
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.mySelect.focus()
    })
  },
  watch: {
    text(newVal, oldVal) {
      this.value = newVal
    },
    roleId: {
      handler(newVal, oldVal) {
        console.log(newVal)
        this.selectedRoleId = newVal
      },
      deep: true
    },
    editable: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.$refs.mySelect.focus()
        })
      }
    }
    // (newVal, oldVal) {
    //   console.log(newVal, 'roleId==============>')
    //   this.selectedRoleId = newVal
    // }
  },
  data() {
    return {
      value: this.text,
      editable: false,
      selectedRoleId: this.roleId ? this.roleId : undefined,
      selectedData: null
    }
  },
  methods: {
    edit() {
      this.editable = true
    },
    confirm() {
      this.editable = false
      // if (this.selectedData) {
      //   this.$emit('change', this.selectedData)
      // }
    },
    selectedRole(id) {
      this.selectedData = this.allRole.filter((item, index) => {
        if (id.includes(item.role_id)) {
          return item
        }
      })
      if (this.selectedData) {
        this.$emit('change', this.selectedData)
      }
    },
    blur() {
      this.confirm()
    }
  }
}
</script>
export const EditableCell = {
template: `

`,

watch: {
text(newVal, oldVal) {
this.value = newVal
},
roleId: {
handler(newVal, oldVal) {
console.log(newVal)
this.selectedRoleId = newVal
},
deep: true
},
editable: {
handler(newVal, oldVal) {
this.$nextTick(() => {
this.$refs.mySelect.focus()
})
}
}
// (newVal, oldVal) {
// console.log(newVal, 'roleId==============>')
// this.selectedRoleId = newVal
// }
},
data() {
return {
value: this.text,
editable: false,
selectedRoleId: this.roleId ? this.roleId : undefined,
selectedData: null
}
},
methods: {
edit() {
this.editable = true
},
confirm() {
this.editable = false
// if (this.selectedData) {
// this.$emit('change', this.selectedData)
// }
},
selectedRole(id) {
this.selectedData = this.allRole.filter((item, index) => {
if (id.includes(item.role_id)) {
return item
}
})
if (this.selectedData) {
this.$emit('change', this.selectedData)
}
},
blur() {
this.confirm()
}
}
}
